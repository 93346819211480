import axios from 'axios'
import { BASEURL } from '../const'
import Store from '../../store'

import { successHandler, errorHandler } from '../handlers'

const URL = `${BASEURL}/auth`

// email: jonathankingcrm@gmail.com

// password: INqSuyT

// _______________________

// email: adegoketemitope1909@gmail.com

// password: MTBxAzxb

export default {
  auth() {
    return {
      login: async ({ email, password }) => {
        const endpoint = `${URL}/login`
        const data = { email, password }
        try {
          const res = await axios.post(endpoint, data)
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      recoverPassword: async ({ email }) => {
        const endpoint = `${URL}/password/recover?email=${email}`
        try {
          const res = await axios.get(endpoint)
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      resetPassword: async ({ token, password, confirmPassword }) => {
        const endpoint = `${URL}/password/reset/${token}`
        const data = { password, confirmPassword }
        try {
          const res = await axios.post(endpoint, data)
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      verifyEmail: async ({ token }) => {
        const endpoint = `${URL}/verify-email/${token}`
        try {
          const res = await axios.get(endpoint)
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      resendEmailVerificationToken: async ({ email }) => {
        const endpoint = `${URL}/verify-token/resend?email=${email}`
        try {
          const res = await axios.get(endpoint)
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      changePassword: async ({ oldPassword, password, confirmPassword }) => {
        const endpoint = `${URL}/password/change`
        try {
          const res = await axios.post(
            endpoint,
            {
              oldPassword,
              password,
              confirmPassword
            },
            {
              headers: {
                ...Store.getters.getRequestHeaders.headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
