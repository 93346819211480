<template>
  <div class="md:my-24">
    <h1 class="mb-1 text-xl font-semibold text-gray-800">Forgot Password</h1>
    <p class="font-light text-sm">
      Enter your email below to reset your password
    </p>
    <p v-if="error.state" class="text-xs text-red-500 my-2 font-semibold">
      {{ error.message }}
    </p>
    <p class="text-xs text-green-500 my-2 font-semibold" v-if="message != ''">
      {{ message }}
    </p>
    <div class="mt-4">
      <label class="leading-relaxed" for="email">Email</label>
      <t-input
        id="email"
        v-model="email"
        name="email"
        type="email"
        placeholder="Your email address"
        :disabled="loading"
      ></t-input>
    </div>
    <div class="mt-4">
      <t-button
        @click="setPassword"
        class="w-full flex space-x-4 justify-center items-center"
      >
        <span>Recover Password</span>
        <spinner v-if="loading"></spinner>
      </t-button>
    </div>
    <hr class="my-8" />
    <p class="mt-4">
      <router-link
        class="text-sm font-medium text-amber-500 hover:underline"
        :to="{ name: 'Login' }"
      >
        Back to Login
      </router-link>
    </p>
  </div>
</template>

<script>
import authApi from '../../api/auth'
import Spinner from '../../components/Utils/Spinner.vue'

export default {
  components: { Spinner },
  name: 'ForgetPassword',
  data() {
    return {
      email: '',
      error: {
        state: false,
        message: ''
      },
      message: '',
      loading: false
    }
  },
  created() {
    this.email = this.$route.query.email
  },
  methods: {
    async setPassword() {
      this.loading = true
      this.error.state = false
      const res = await authApi.auth().recoverPassword({ email: this.email })
      if (res.error) {
        this.error.state = true
        this.error.message = res.errorMessage
        this.loading = false
        return
      }
      this.error.state = false

      this.message = res.message
      this.loading = false
    }
  }
}
</script>

<style></style>
